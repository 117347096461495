import {
  CrmLayoutCollapseComponent,
  CrmLayoutCollapseConfig,
  CrmLayoutLogoComponent,
  CrmLayoutLogoConfig,
  CrmLayoutMenuComponent,
  CrmLayoutMenuConfig,
  CrmLayoutProfileComponent,
  CrmLayoutProfileConfig,
  CrmLayoutV2Config,
  CrmLayoutVersionComponent,
} from 'common-module/layout-v2';
import { LayoutProfileProvider } from '@app/core/layout/layout-profile.provider';
import { AppRoutes } from '@app/app.routes';

import { userRoleMap } from './roles.config';

export enum ProfileKey {
  logout = 'logout',
  support = 'support',
  history = 'history',
}

export const layoutConfig: CrmLayoutV2Config = {
  logo: {
    defaultLogoPath: './assets/images/logo.svg',
    smallLogoPath: './assets/icons/logo-small.svg',
  } as CrmLayoutLogoConfig,
  siderLeft: {
    width: '260px',
    collapsedWidth: 64,
    theme: 'light',
    collapseDisabled: false,
    collapseBreakpoint: 3000,
    persistentCollapse: true,
    defaultCollapsed: true,
    fragments: [
      {
        key: 'logo',
        content: CrmLayoutLogoComponent,
      },
      {
        key: 'menu',
        content: CrmLayoutMenuComponent,
      },
      {
        key: 'collapse',
        content: CrmLayoutCollapseComponent,
      },
      {
        key: 'profile',
        content: CrmLayoutProfileComponent,
        provider: LayoutProfileProvider,
      },
    ],
  },
  menu: {
    items: [
      {
        routeKey: AppRoutes.dashboard,
        key: 'layout.menu.dashboard',
        icon: 'home',
      },
      {
        routeKey: AppRoutes.users,
        key: 'layout.menu.users.title',
        icon: 'usergroup-add',
        children: [
          {
            routeKey: 'clients',
            key: 'layout.menu.users.clients',
          },
          {
            routeKey: 'system',
            key: 'layout.menu.users.system',
          },
        ],
      },
      {
        routeKey: AppRoutes.segments,
        key: 'layout.menu.segments',
        icon: 'tags',
      },
      {
        routeKey: AppRoutes.benefits,
        key: 'layout.menu.benefits.title',
        icon: 'heart',
        children: [
          {
            routeKey: 'active',
            key: 'layout.menu.benefits.active',
          },
          {
            routeKey: 'inactive',
            key: 'layout.menu.benefits.inactive',
          },
          {
            routeKey: 'system',
            key: 'layout.menu.benefits.system',
          },
        ],
      },
      {
        routeKey: AppRoutes.transactions,
        key: 'layout.menu.transactions',
        icon: 'bar-chart',
      },
      {
        routeKey: AppRoutes.reports,
        key: 'layout.menu.reports',
        icon: 'download',
      },
    ],
  } as CrmLayoutMenuConfig,
  profile: {
    roleMap: userRoleMap,
  } as CrmLayoutProfileConfig,
  collapse: {
    text: undefined,
    icon: 'menu-fold',
    iconCollapsed: 'menu-unfold',
  } as CrmLayoutCollapseConfig,
  content: {
    footer: {
      fragments: [
        {
          key: 'version',
          content: CrmLayoutVersionComponent,
        },
      ],
    },
  },
};
