import { CrmAuthConfig } from 'common-module/auth';

export const authModuleConfig = {
  auth: {
    components: {
      wrapper: {
        imagePosition: 'right',
        imageSrc: '/assets/images/login-1.jpg',
        layout: 'image',
        logoSrc: '/assets/icons/logo.svg',
      },
      login: {
        messages: {
          showText: false,
        },
        showForgottenPassword: false,
      },
    },
  } as CrmAuthConfig,
};
