import { NgModule } from '@angular/core';
import { CrmModuleConfigToken } from 'common-module/core';
import { layoutConfig } from '@app/config/layout.config';
import { CrmVersionModule } from 'common-module/version';
import { CrmChangelogService } from 'common-module/changelog';

@NgModule({
  imports: [
    CrmVersionModule.forRoot({
      enabled: true,
    }),
  ],
  exports: [],
  providers: [
    CrmChangelogService,
    {
      provide: CrmModuleConfigToken,
      useValue: { layoutV2: layoutConfig },
      multi: true,
    },
  ],
})
export class LayoutModule {}
