import { Component } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { CrmAppComponent } from 'common-module/core';

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [RouterOutlet, CrmAppComponent],
  templateUrl: './app.component.html',
  styleUrl: './app.component.less'
})
export class AppComponent {
  title = 'gate-admin';
}
