import * as i0 from '@angular/core';
import { EventEmitter, Component, ChangeDetectionStrategy, Input, Output, InjectionToken, inject, Injectable, signal, HostBinding, APP_INITIALIZER, NgModule } from '@angular/core';
import { CrmMessageService } from 'common-module/message';
import { CrmLocaleServiceToken, CrmTranslatePipe, CrmTranslateService } from 'common-module/translate';
import { DatePipe } from '@angular/common';
import { CrmConfigService, CrmOnceSubject, crmFilterNullish, CrmModuleConfigToken } from 'common-module/core';
import { compareAsc } from 'date-fns';
import { from, of } from 'rxjs';
import { switchMap, map } from 'rxjs/operators';
import { CrmModalRefDirective, crmCreateModalHandlersFn } from 'common-module/modal';
function CrmChangelogItemComponent_Conditional_6_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelement(0, "div", 3);
  }
  if (rf & 2) {
    const ctx_r0 = i0.ɵɵnextContext();
    i0.ɵɵproperty("innerHTML", ctx_r0.item.notes, i0.ɵɵsanitizeHtml);
  }
}
function CrmChangelogItemComponent_Conditional_7_Conditional_1_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "h3");
    i0.ɵɵtext(1);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const ctx_r0 = i0.ɵɵnextContext(2);
    i0.ɵɵadvance();
    i0.ɵɵtextInterpolate(ctx_r0.item.title);
  }
}
function CrmChangelogItemComponent_Conditional_7_For_3_Conditional_0_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "h4");
    i0.ɵɵtext(1);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const section_r2 = i0.ɵɵnextContext().$implicit;
    i0.ɵɵadvance();
    i0.ɵɵtextInterpolate(section_r2.title);
  }
}
function CrmChangelogItemComponent_Conditional_7_For_3_Conditional_1_For_2_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "li");
    i0.ɵɵtext(1);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const item_r3 = ctx.$implicit;
    i0.ɵɵadvance();
    i0.ɵɵtextInterpolate(item_r3);
  }
}
function CrmChangelogItemComponent_Conditional_7_For_3_Conditional_1_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "ul");
    i0.ɵɵrepeaterCreate(1, CrmChangelogItemComponent_Conditional_7_For_3_Conditional_1_For_2_Template, 2, 1, "li", null, i0.ɵɵrepeaterTrackByIndex);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const section_r2 = i0.ɵɵnextContext().$implicit;
    i0.ɵɵadvance();
    i0.ɵɵrepeater(section_r2.items);
  }
}
function CrmChangelogItemComponent_Conditional_7_For_3_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵtemplate(0, CrmChangelogItemComponent_Conditional_7_For_3_Conditional_0_Template, 2, 1, "h4")(1, CrmChangelogItemComponent_Conditional_7_For_3_Conditional_1_Template, 3, 0, "ul");
  }
  if (rf & 2) {
    const section_r2 = ctx.$implicit;
    i0.ɵɵconditional(0, section_r2.title ? 0 : -1);
    i0.ɵɵadvance();
    i0.ɵɵconditional(1, section_r2.items ? 1 : -1);
  }
}
function CrmChangelogItemComponent_Conditional_7_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "div", 4);
    i0.ɵɵtemplate(1, CrmChangelogItemComponent_Conditional_7_Conditional_1_Template, 2, 1, "h3");
    i0.ɵɵrepeaterCreate(2, CrmChangelogItemComponent_Conditional_7_For_3_Template, 2, 2, null, null, i0.ɵɵrepeaterTrackByIndex);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const ctx_r0 = i0.ɵɵnextContext();
    i0.ɵɵadvance();
    i0.ɵɵconditional(1, ctx_r0.item.title ? 1 : -1);
    i0.ɵɵadvance();
    i0.ɵɵrepeater(ctx_r0.item.sections);
  }
}
const _forTrack0 = ($index, $item) => $item.version;
function CrmChangelogListComponent_For_4_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "div", 0);
    i0.ɵɵelement(1, "crm-changelog-item", 1);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const item_r1 = ctx.$implicit;
    i0.ɵɵadvance();
    i0.ɵɵproperty("item", item_r1);
  }
}
class CrmChangelogItemComponent {
  constructor() {
    /**
     * Event emitter for click on change item handling
     */
    this.itemClicked = new EventEmitter();
  }
  static {
    this.ɵfac = function CrmChangelogItemComponent_Factory(t) {
      return new (t || CrmChangelogItemComponent)();
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: CrmChangelogItemComponent,
      selectors: [["crm-changelog-item"]],
      inputs: {
        item: "item"
      },
      outputs: {
        itemClicked: "itemClicked"
      },
      standalone: true,
      features: [i0.ɵɵStandaloneFeature],
      decls: 8,
      vars: 6,
      consts: [[1, "crm-changelog-item__header"], [3, "click"], [1, "crm-changelog-item__subtitle"], [1, "crm-changelog-item__body", 3, "innerHTML"], [1, "crm-changelog-item__body"]],
      template: function CrmChangelogItemComponent_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵelementStart(0, "div", 0)(1, "h2", 1);
          i0.ɵɵlistener("click", function CrmChangelogItemComponent_Template_h2_click_1_listener() {
            return ctx.itemClicked.emit(ctx.item);
          });
          i0.ɵɵtext(2);
          i0.ɵɵelementEnd();
          i0.ɵɵelementStart(3, "h5", 2);
          i0.ɵɵtext(4);
          i0.ɵɵpipe(5, "date");
          i0.ɵɵelementEnd()();
          i0.ɵɵtemplate(6, CrmChangelogItemComponent_Conditional_6_Template, 1, 1, "div", 3)(7, CrmChangelogItemComponent_Conditional_7_Template, 4, 1);
        }
        if (rf & 2) {
          i0.ɵɵadvance(2);
          i0.ɵɵtextInterpolate(ctx.item.version);
          i0.ɵɵadvance(2);
          i0.ɵɵtextInterpolate1(" ", i0.ɵɵpipeBind2(5, 3, ctx.item.releaseDate, "dd. LLLL y"), " ");
          i0.ɵɵadvance(2);
          i0.ɵɵconditional(6, ctx.item.notes ? 6 : ctx.item.sections ? 7 : -1);
        }
      },
      dependencies: [DatePipe],
      encapsulation: 2,
      changeDetection: 0
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(CrmChangelogItemComponent, [{
    type: Component,
    args: [{
      standalone: true,
      selector: 'crm-changelog-item',
      template: `
    <div class="crm-changelog-item__header">
      <h2 (click)="itemClicked.emit(item)">{{ item.version }}</h2>
      <h5 class="crm-changelog-item__subtitle">
        {{ item.releaseDate | date: 'dd. LLLL y' }}
      </h5>
    </div>

    @if (item.notes) {
      <div class="crm-changelog-item__body" [innerHTML]="item.notes"></div>
    } @else if (item.sections) {
      <div class="crm-changelog-item__body">
        @if (item.title) {
          <h3>{{ item.title }}</h3>
        }

        @for (section of item.sections; track index; let index = $index) {
          @if (section.title) {
            <h4>{{ section.title }}</h4>
          }

          @if (section.items) {
            <ul>
              @for (
                item of section.items;
                track itemIndex;
                let itemIndex = $index
              ) {
                <li>{{ item }}</li>
              }
            </ul>
          }
        }
      </div>
    }
  `,
      imports: [DatePipe],
      changeDetection: ChangeDetectionStrategy.OnPush
    }]
  }], null, {
    item: [{
      type: Input
    }],
    itemClicked: [{
      type: Output
    }]
  });
})();
const defaultChangelogConfiguration = {
  changelogModal: false,
  changelogStatic: true,
  changelogStaticPath: '/assets/static/changelog.json'
};
const CrmChangelogProviderToken = new InjectionToken('CrmChangelogProviderToken');
class CrmChangelogProvider {
  constructor() {
    this.configService = inject(CrmConfigService);
    this.localeService = inject(CrmLocaleServiceToken, {
      optional: true
    });
  }
  loadData() {
    const {
      version
    } = this.configService.getConfig('environment');
    const config = this.configService.mergeConfig(defaultChangelogConfiguration, 'modules.changelog');
    if (config.changelogStatic) {
      return from(fetch(`${config.changelogStaticPath}?v=${version}`)).pipe(switchMap(response => response.json()), map(res => this.processData(res)));
    }
    return of([]);
  }
  findVersion(version) {
    return this.loadData().pipe(map(res => res.find(item => item.version === version)));
  }
  latestVersion() {
    const dateSort = (a, b) => new Date(b.releaseDate).valueOf() - new Date(a.releaseDate).valueOf();
    return this.loadData().pipe(map(res => res.sort(dateSort)[0]));
  }
  processData(content) {
    if (!content || !content.length) {
      return [];
    }
    this.changeItems = this.getChangeItems(content);
    return this.changeItems.sort((a, b) => compareAsc(new Date(b.releaseDate), new Date(a.releaseDate)));
  }
  getChangeItems(content) {
    const locale = this.localeService?.currentLocale() ?? 'en';
    const langChanges = content.find(r => r.locale === locale);
    return langChanges?.value ?? content.find(r => r.locale === 'en')?.value ?? [];
  }
  static {
    this.ɵfac = function CrmChangelogProvider_Factory(t) {
      return new (t || CrmChangelogProvider)();
    };
  }
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: CrmChangelogProvider,
      factory: CrmChangelogProvider.ɵfac
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(CrmChangelogProvider, [{
    type: Injectable
  }], null, null);
})();
class CrmChangelogListComponent {
  constructor() {
    this.hostClass = 'crm-changelog';
    this.changeItems = signal([]);
    this.messageService = inject(CrmMessageService);
    this.provider = inject(CrmChangelogProviderToken);
  }
  ngOnInit() {
    this.loadData();
  }
  loadData() {
    this.provider.loadData().subscribe({
      next: res => this.changeItems.set(res),
      error: err => {
        this.messageService.error('errors.changesLoadingFailed', {}, err);
      }
    });
  }
  static {
    this.ɵfac = function CrmChangelogListComponent_Factory(t) {
      return new (t || CrmChangelogListComponent)();
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: CrmChangelogListComponent,
      selectors: [["crm-changelog"]],
      hostVars: 2,
      hostBindings: function CrmChangelogListComponent_HostBindings(rf, ctx) {
        if (rf & 2) {
          i0.ɵɵclassMap(ctx.hostClass);
        }
      },
      standalone: true,
      features: [i0.ɵɵStandaloneFeature],
      decls: 5,
      vars: 3,
      consts: [[1, "crm-changelog__item"], [3, "item"]],
      template: function CrmChangelogListComponent_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵelementStart(0, "h2");
          i0.ɵɵtext(1);
          i0.ɵɵpipe(2, "crmTranslate");
          i0.ɵɵelementEnd();
          i0.ɵɵrepeaterCreate(3, CrmChangelogListComponent_For_4_Template, 2, 1, "div", 0, _forTrack0);
        }
        if (rf & 2) {
          i0.ɵɵadvance();
          i0.ɵɵtextInterpolate(i0.ɵɵpipeBind1(2, 1, "crm.changelog.header.title"));
          i0.ɵɵadvance(2);
          i0.ɵɵrepeater(ctx.changeItems());
        }
      },
      dependencies: [CrmTranslatePipe, CrmChangelogItemComponent],
      encapsulation: 2,
      changeDetection: 0
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(CrmChangelogListComponent, [{
    type: Component,
    args: [{
      standalone: true,
      selector: 'crm-changelog',
      template: `
    <h2>{{ 'crm.changelog.header.title' | crmTranslate }}</h2>

    @for (item of changeItems(); track item.version) {
      <div class="crm-changelog__item">
        <crm-changelog-item [item]="item"></crm-changelog-item>
      </div>
    }
  `,
      imports: [CrmTranslatePipe, CrmChangelogItemComponent],
      changeDetection: ChangeDetectionStrategy.OnPush
    }]
  }], null, {
    hostClass: [{
      type: HostBinding,
      args: ['class']
    }]
  });
})();
const CrmChangelogModalTriggerToken = new InjectionToken('CrmChangelogModalTriggerToken');
class CrmChangelogModalTrigger {
  constructor() {
    this.trigger$ = new CrmOnceSubject();
  }
  static {
    this.ɵfac = function CrmChangelogModalTrigger_Factory(t) {
      return new (t || CrmChangelogModalTrigger)();
    };
  }
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: CrmChangelogModalTrigger,
      factory: CrmChangelogModalTrigger.ɵfac
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(CrmChangelogModalTrigger, [{
    type: Injectable
  }], null, null);
})();
class CrmChangelogModalComponent extends CrmModalRefDirective {
  static {
    this.ɵfac = /* @__PURE__ */(() => {
      let ɵCrmChangelogModalComponent_BaseFactory;
      return function CrmChangelogModalComponent_Factory(t) {
        return (ɵCrmChangelogModalComponent_BaseFactory || (ɵCrmChangelogModalComponent_BaseFactory = i0.ɵɵgetInheritedFactory(CrmChangelogModalComponent)))(t || CrmChangelogModalComponent);
      };
    })();
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: CrmChangelogModalComponent,
      selectors: [["crm-changelog-modal"]],
      standalone: true,
      features: [i0.ɵɵInheritDefinitionFeature, i0.ɵɵStandaloneFeature],
      decls: 1,
      vars: 1,
      consts: [[3, "item"]],
      template: function CrmChangelogModalComponent_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵelement(0, "crm-changelog-item", 0);
        }
        if (rf & 2) {
          i0.ɵɵproperty("item", ctx.data.item);
        }
      },
      dependencies: [CrmChangelogItemComponent],
      encapsulation: 2,
      changeDetection: 0
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(CrmChangelogModalComponent, [{
    type: Component,
    args: [{
      standalone: true,
      selector: 'crm-changelog-modal',
      template: `<crm-changelog-item [item]="data.item"></crm-changelog-item>`,
      imports: [CrmChangelogItemComponent],
      changeDetection: ChangeDetectionStrategy.OnPush
    }]
  }], null, null);
})();
const APP_VERSION_VISITED = 'crm_app_version_visited';
class CrmChangelogService {
  constructor() {
    this.configService = inject(CrmConfigService);
    this.translate = inject(CrmTranslateService);
    this.dataProvider = inject(CrmChangelogProviderToken, {
      optional: true
    });
    this.createModal = crmCreateModalHandlersFn();
  }
  changelogModal() {
    if (!this.dataProvider) {
      return;
    }
    const config = this.configService.mergeConfig(defaultChangelogConfiguration, 'modules.changelog');
    if (!config.changelogModal) {
      return;
    }
    const {
      version
    } = this.configService.getConfig('environment');
    const versionVisited = localStorage.getItem(APP_VERSION_VISITED);
    if (!version || versionVisited === version) {
      return;
    }
    this.dataProvider.findVersion(version).pipe(crmFilterNullish()).subscribe(item => {
      localStorage.setItem(APP_VERSION_VISITED, version);
      this.createModal({
        component: CrmChangelogModalComponent,
        data: {
          item
        },
        modalOptions: {
          nzTitle: this.translate.get('changelog.header.title')
        }
      });
    });
  }
  static {
    this.ɵfac = function CrmChangelogService_Factory(t) {
      return new (t || CrmChangelogService)();
    };
  }
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: CrmChangelogService,
      factory: CrmChangelogService.ɵfac
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(CrmChangelogService, [{
    type: Injectable
  }], null, null);
})();
class CrmChangelogModule {
  static forRoot(config) {
    return {
      ngModule: CrmChangelogModule,
      providers: [{
        provide: CrmModuleConfigToken,
        useValue: {
          changelog: config
        },
        multi: true
      }, CrmChangelogService, {
        provide: CrmChangelogProviderToken,
        useClass: CrmChangelogProvider
      }, {
        provide: CrmChangelogModalTriggerToken,
        useClass: CrmChangelogModalTrigger
      }, {
        provide: APP_INITIALIZER,
        multi: true,
        deps: [CrmChangelogService, CrmChangelogModalTriggerToken],
        useFactory: (service, trigger) => () => trigger.trigger$.subscribe(() => service.changelogModal())
      }]
    };
  }
  static {
    this.ɵfac = function CrmChangelogModule_Factory(t) {
      return new (t || CrmChangelogModule)();
    };
  }
  static {
    this.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
      type: CrmChangelogModule
    });
  }
  static {
    this.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({});
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(CrmChangelogModule, [{
    type: NgModule
  }], null, null);
})();

/**
 * Generated bundle index. Do not edit.
 */

export { CrmChangelogItemComponent, CrmChangelogListComponent, CrmChangelogModalComponent, CrmChangelogModalTrigger, CrmChangelogModalTriggerToken, CrmChangelogModule, CrmChangelogProvider, CrmChangelogProviderToken, CrmChangelogService, defaultChangelogConfiguration };
