import { NgModule } from '@angular/core';
import { CrmUserService } from 'common-module/user';
import { UserService } from '@app/core/users/user.service';

@NgModule({
  imports: [],
  exports: [],
  declarations: [],
  providers: [
    {
      provide: CrmUserService,
      useExisting: UserService,
    },
  ],
})
export class UsersModule {}
