import { CrmEndpointModuleConfig } from 'common-module/endpoint';

export const environment = {
  production: false,
  projectName: 'GATE',
  env: 'dev',
  version: '2.0.0',
  baseUrl: `https://api.admin.gate.touch4dev.net`,
  basicAuthToken: 'Z2F0ZTpYNm56bXhqVGY2SmJ1SEpjQUtDNQ==',
};

export const endpointModuleConfiguration: CrmEndpointModuleConfig = {
  endpoints: [
    {
      name: 'crm',
      baseUrl: environment.baseUrl,
      httpHeaders: {
        Authorization: `Basic ${environment.basicAuthToken}`,
      },
      withCredentials: true,
    },
  ],
};
