import { Injectable } from '@angular/core';
import {
  CrmLayoutProfileProvider,
  CrmLayoutSiderControl,
} from 'common-module/layout-v2';
import { CrmUserProfileItem } from 'common-module/user-profile';

@Injectable({ providedIn: 'root' })
export class LayoutProfileProvider extends CrmLayoutProfileProvider {
  override handleItemClicked(config: {
    event: MouseEvent;
    item: CrmUserProfileItem;
    siderControl?: CrmLayoutSiderControl;
  }) {
    return super.handleItemClicked(config);
  }
}
