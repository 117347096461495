import en from '@angular/common/locales/en';
import sk from '@angular/common/locales/sk';
import { en_US, sk_SK } from 'ng-zorro-antd/i18n';
import { defaultTranslateConfig } from 'common-module/translate';

import { environment } from '../../environments/environment';

export const crmAppConfig = {
  environment,
  modules: {
    translate: {
      ...defaultTranslateConfig,
      locale: {
        defaultLocale: 'en',
        locales: {
          en: {
            ngLocale: en,
            nzLocale: en_US,
          },
          sk: {
            ngLocale: sk,
            nzLocale: sk_SK,
          },
        },
      },
    },
  },
};
